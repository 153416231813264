<template>
  <window-content>
    <div class="wrapper-sm">
      <e-row mr>

        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="ID do Leilão"
          >
            <erp-input v-model="filters.id" />
          </erp-s-field>
        </e-col>

        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="Código"
          >
            <erp-input v-model="filters.codigo" />
          </erp-s-field>
        </e-col>
      </e-row>
    </div>
    <window-footer class="text-right">
      <div class="inline-block window-actions">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)"/>
        <u-btn @click="gerar(formato)" :loading="loading" label="Gerar" icon="print" icon-type="fa" icon-style="light" flat no-caps/>
      </div>
    </window-footer>
  </window-content>
</template>

<script>
import {
  WindowContent,
  WindowFooter,
  ErpSField,
  ErpInput,
  /*ErpSelect*/
} from 'uloc-vue-plugin-erp'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import mixinPrint from "@/components/relatorios/components/mixinPrint"
import {relatorio} from "@/domain/relatorios/services"
import createPrint from "@/components/relatorios/components/customPrint/createPrint"

let filters = {
  id: null,
  codigo: null
}

export default {
  name: 'RelacaoPatioLeilaooWindow',
  mixins: [mixinPrint],
  data() {
    return {
      formato: 'html',
      filters: JSON.parse(JSON.stringify(filters))
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    createPrint: createPrint,
    actionPrint (action) {
      console.log('Print Action ', action)
      this.gerar(action)
    },
    gerar(formato = 'html') {
      let filters = JSON.parse(JSON.stringify(this.filters))

      if (!filters.id && !filters.codigo) {
        this.$uloc.dialog({
          className: 'erp-confirm c-error',
          title: 'Preenchimento inválido',
          color: 'info',
          message:'Preencha o ID ou o código do leilão'
        })
        return
      }

      this.loading = true
      relatorio('relacaoPatioLeilao', filters, formato)
          .then(response => {
            this.loading = false
            formato === 'html' && this.createPrint(response.data, () => import('@/components/relatorios/components/customPrint/DefaultPrint'), 'gerencial', 'Confirmação de Dados de Veículos para Leilão', 'landscape')
          })
          .catch(error => {
            this.loading = false
            if (formato === 'html') {
              this.alertApiError(error)
            } else{
              error.data.text().then(text => {
                this.alertApiError({data: JSON.parse(text)})
              })
            }
          })
    }
  },
  components: {
    ECol,
    ERow,
    WindowFooter,
    WindowContent,
    ErpSField,
    ErpInput,
  }
}
</script>
